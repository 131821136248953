// var SERVER = "LOCALHOST";
// var SERVER = "STAGING";
var SERVER = "PRODUCTION";
var API_URL = "http://localhost:4025";
var WEBSITE_URL = "localhost:3000";
var API_HOST = "http://localhost:4025";
var CHAT_HOST = "http://localhost:4027";

if (SERVER == "STAGING") {
  API_URL = "https://159.89.80.249/api";
  WEBSITE_URL = "159.89.80.249";
  API_HOST = "https://159.89.80.249";
  CHAT_HOST = "https://159.89.80.249";
} else if (SERVER == "PRODUCTION") {
  API_URL = "https://shadowing.ai/api";
  WEBSITE_URL = "shadowing.ai";
  API_HOST = "https://shadowing.ai";
  CHAT_HOST = "https://shadowing.ai";
}

export var API_URL;
export var WEBSITE_URL;
export var API_HOST;
export var CHAT_HOST;

export const FACEBOOK_APP_ID = "425843430824148";
export const INSTAGRAM_CLIENT_ID = "ee570c48ace248caa79743e5e024efe6";
export const TWITTER_APP_ID = "425843430824148";
// export const GOOGLE_APP_ID = '467206642591-kdsg3p8vv82119iqfd9pdk6vspdqhlec.apps.googleusercontent.com'; //Convoocean Account
export const GOOGLE_APP_ID =
  "319739464865-7c77r8ik78dpu050v78q9980q752k9r1.apps.googleusercontent.com"; // ShadowingAI - Main shadowingai11@gmail.com
export const GOOGLE_APP_SECRET = "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"; // ShadowingAI - Main shadowingai11@gmail.com

export const DEFAULT_IMAGE =
  "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740753.jpg";

export const DESKTOP = ["Twitter Web App", "TweetDeck", "Twitter Web Client"];
export const ANDROID = ["Twitter for Android", "TweetCaster for Android"];
export const IOS = ["Twitter for iPhone", "Twitter for iPad"];

//test
// export const STRIPE_API_KEY = 'pk_test_51JFTegJOh5q0pIy17mq3lRjzDQPq2ZNunqms4ISikCaND4yllXbhGWUzgOY82DGSM6IWkdyK4ec0QVf8Jhs6jeHA00jlQlvMQK';
//Live
// export const STRIPE_API_KEY = 'sk_test_51JFTegJOh5q0pIy1SeVvXSk6kSI71I86obekcRgwbmmn7hX2Zn191mFC8FGozcXVD2JrK7pYFopKnlFdaxO4iEgb001DLDchAX';

export const STRIPE_API_KEY =
  "pk_live_51JFTegJOh5q0pIy1796HeCfnbl6hOSOHhwQtL7lHY0Gy08a99gCzvfIYLZBcFdnyl8RFPv5qCZaxRBWadyvKnzo3005UXWW2hS"; //Shadowing test

// export const STRIPE_API_KEY = 'pk_test_51Ls5xPB50lVXD1qQdkYmo9jgpfxbKrwzrdSLkkjJefsKoVmRVzUq8pcMt6zIYhSV5tdXv8J2mTZM1HRcQ6GSIrWb00e4YjjNyi' //Shadowing test

// export const STRIPE_API_KEY = 'pk_test_51H7lz2LoZFlWEdrmuo6YT3Ivgy1j77mX2LKPKmQ8BSEC6KQ7vLDYOdx3QkVgDQtEBUbKfoFax4Zyf4ltfeiawfhr00bQgpp66V' //convocean test

export const SEO_TAGS = {
  title: "Live Interview Preparation | Job, Uni & Visa Practices",
  meta_description:
    "Master your interviews with Shadowing AI. Practice live for job, university and visa interviews. Get acesss to actionable feedback, resume scorecards, question bank"
  // meta_keywords:'Shadowing, shadowing work, onboarding, your onboarding, internal onboarding, employee onboarding, new employee onboarding, cohort onboarding, group onboarding, cohort shadowing, progression planning, student progression plan, pre hire assessment, pre hire testing, pre hiring process, pre hire, pre hiring rounds, post hiring, hiring post, job hiring post, now hiring post, hiring job post, cohort based learning, applicant assessment tools, assessment for recruitment, applicant assessment test, recruitment assessment test, new hire assessment, new hire assessment tests, technical recruitment assessment test, technical hiring assessment test, product management hiring assessment test, product manager hiring assessment test, product management recruitment assessment test, product manager recruitment assessment test, pre hire assessment tools, pre hire assessment test, employment assessment tools, hiring assessment tools, hiring assessment test, pre employment assessment tools, pre hire assessment, employment assessment, employment assessment test, pre employment assessment, pre employment assessment test, employment test, shadow manager in the company, shadow software developer, shadow software engineer, shadow designer, shadowing guide, shadowing mentor, learn from mentors, learn from guide, hiring through shadowing, hiring via shadowing, women mentors, coding skills assessment test, online coding assessment, hire programming skills assessment, coding assessment test, job shadow, shadowing definition,Mock Interview,Mock Interview practice, Interview practice rooms,internship, entry level position, mirror room, mentor room',
};

export const DEFAULT_IMAGES = {
  company: "/assets/img/company_default.png",
  user: "/assets/img/default_user.png"
  // user:'https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740753.jpg',
};
/* Pratik SANDBOX */
export const PAYPAL_ENV = "sandbox";
export const PAYPAL_CLIENT_ID =
  "AcE5KiF80bQx1gzH1vaOXHH6jbHJSH8I3zw3gof3wHSFaLCW93tEJx7VTRf96gsXpOBuwbnPAWfQYz1j";
export const PAYPAL_CLIENT_SECRET =
  "EDgGtzTwBegyIiTUAxVXX2G590TKJcO_72PgZ9wOlEgrbUgCOKsjn23B3VOks9lWThzIh0NVS42tX1-T";
export const PAYPAL_CURRENCY = "USD";
