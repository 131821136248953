import React from "react";
import { connect } from "react-redux";
// import {ADMIN_URL} from '../../config/config';
import { Link, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getHomeDomains } from "../../actions/auth";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidMount() {
  //   this.props.getHomeDomains({});
  // }

  render() {
    var { company_domain } = this.props;

    var d = new Date();
    var current_year = d.getFullYear();
    if (
      this.props.location.pathname.startsWith("/videocall") ||
      this.props.location.pathname.startsWith("/video-room") ||
      this.props.location.pathname.startsWith("/live-room/") ||
      this.props.location.pathname.startsWith("/mirror-room/") ||
      (this.props.location.pathname.startsWith("/one-to-one-practice-room/") &&
        this.props.location.pathname.split("/").length == 4)
    ) {
      return null;
    }
    var { home_domains } = this.props;
    var new_domains =
      home_domains && home_domains.filter(o => o.is_display == 1);
    console.log(home_domains);
    var currentYear = new Date().getFullYear();
    var params = [
      "Performance Reviews at ",
      "Culture Fit Assessment at ",
      "Self assessment of mental health at ",
      "AI-powered feedback for interviews at "
    ];

    return (
      <React.Fragment>
        <footer id="footer" className="footer">
          <div className="footer-top px-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <p className="title">ShadowingAI</p>
                  <p className="mb-3">
                    Shadowing AI offers a suite of AI features that help
                    candidates prepare for different stages of their career, in
                    an affordable and personalized manner. Our mission is to
                    empower people globally to reach their full potential in
                    their careers.
                  </p>
                  {/* <p className="mb-3">
                    The platform is available for free to practice in <span style={{color: 'bisque',fontSize: '17px',textDecoration: 'underline',fontWeight:'bolder'}}>60+ languages</span> and gives instant feedback.
                  </p> */}
                  {/* <p><a href="https://shadowing.ai" target="_blank">https://shadowing.ai</a></p> */}

                  {/* <p>© Copyright 2015 by Nevia. All Rights Reserved.</p> */}
                </div>
                <div className="col-lg-1 col-md-6 footer-links" />
                {!company_domain ? (
                  <React.Fragment>
                    <div className="col-lg-2 col-md-6 col-sm-6 footer-links">
                      <p>About</p>
                      <ul>
                        {/* <li><Link to="/" >Home</Link></li> */}
                        <li>
                          <Link to="/pricing">Pricing</Link>
                        </li>
                        <li>
                          <Link to="/about-us">Team</Link>
                        </li>
                        <li>
                          <Link to="/career">Careers</Link>
                        </li>
                        <li>
                          <Link to="/contact-us">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/faqs">FAQs</Link>
                        </li>
                        {/* <li><Link to='/campus-ambassadors' >Campus Ambassador</Link></li> */}
                        {/* <li><Link to='/company/view/9809ae427763r56ef-shadowing-ai/positions'>Career</Link></li> */}
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 footer-links">
                      <p>Resources</p>
                      <ul>
                        {/* <li> <Link to='/interview/job_hiring'>Interview Practices</Link></li>
                      <li> <Link to='/interview/university_admission'>College Interviews</Link></li>
                      <li> <Link to='/interview/visa_preparation'>Visa Interviews</Link></li> */}
                        {/* <li> <Link to='/one-to-one-practice-rooms'>One to One Rooms</Link></li>     */}
                        {/* <li> <Link to='/pre-defined-ai-mirror-rooms'>Predefined AI Interview Practices</Link></li> */}
                        {/* <li> <Link to='/interview/question_bank'>Question Bank</Link></li> */}
                        {/* <li> <Link to='/question-practice'>BYOQ</Link></li> */}
                        <li>
                          <Link to="/developer-access">Developer API</Link>
                        </li>
                        <li>
                          <Link to="/school">School (Beta)</Link>
                        </li>
                        <li>
                          {" "}
                          <Link to="/hirings">Search by Company</Link>
                        </li>
                        <li>
                          {" "}
                          <Link to="/blogs">Blog</Link>
                        </li>
                        {/* <li> <Link to='/role_discovery_info'>Role Discovery</Link></li>
                      <li> <Link to='/resume_enhancer_info'>Resume Enhancer</Link></li>
                      <li> <Link to='/recruiter_chatbot_info'>AI Recruiter</Link></li> */}
                        {/* <li> <Link to='/questions_bank_info'>Interview Master Library</Link></li> */}
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 footer-links">
                      <p>Policies</p>
                      <ul>
                        <li>
                          <Link to="/terms">Terms</Link>
                        </li>
                        <li>
                          <Link to="/privacy_policy">Privacy Policy</Link>
                        </li>
                        {/* <li> <Link to='/how-it-works'>How it works</Link></li> */}
                        {/* <li> <Link to='/tournaments'>Tournaments</Link></li> */}
                        {/* <li> <Link to='/tournament-leaderboard'>Leaderboard</Link></li> */}
                        {/* <li> <Link to='/for_students'>College Students</Link></li> */}
                        {/* <li> <Link to='/for_mentors'>Mentors / Coaches</Link></li> */}
                        {/* <li> <Link to='/for_companies'>Companies</Link></li> */}
                        {/* <li> <Link to='/universities'>Universities</Link></li> */}
                        {/* <li> <Link to='/what_is_shadowing'>What Is Shadowing</Link></li>
                      <li> <Link to='/how_shadowing_works'>How Shadowing Works</Link></li> */}
                        {/* <li> <Link to='/newsletter'>Newsletters</Link></li> */}
                        {/* <li> <Link to='/college_admissions'>College Admissions</Link></li> */}
                        {/* <li> <Link to='/mirror-room-company'>Interview Practices by Company</Link></li>
                      <li> <Link to='/mirror-room-position'>Interview Practices by Position</Link></li> */}
                      </ul>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="col-lg-2 col-md-6 col-sm-6 footer-links">
                      <p>About</p>
                      <ul>
                        <li>
                          <Link to="/developer-access">While Label API</Link>
                        </li>
                        <li>
                          <Link to="/about-us">Team</Link>
                        </li>
                        <li>
                          <Link to="/faqs">FAQs</Link>
                        </li>
                        <li>
                          <Link to="/contact-us">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/terms">Terms</Link>
                        </li>
                        <li>
                          <Link to="/privacy_policy">Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 footer-links">
                      <p>AI Practices</p>
                      <ul>
                        <li>
                          {" "}
                          <Link to="/interview/question_bank">
                            Interview Prep Questions
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 footer-links">
                      <p>Shadowing AI</p>
                      <ul>
                        <li>
                          {" "}
                          <Link to="/how-it-works">How it works</Link>
                        </li>
                        <li>
                          {" "}
                          <Link to="/for_companies">Companies</Link>
                        </li>
                        <li>
                          {" "}
                          <Link to="/blogs">Blogs</Link>
                        </li>
                      </ul>
                    </div>
                  </React.Fragment>
                )}

                {/* <div className="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Join Our Newsletter</h4>
                  <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                  <form action="" method="post">
                    <input type="email" name="email"/><input type="submit" value="Subscribe" />
                  </form>
                </div> */}
              </div>
              {/* <Row className="mb-5">
                    {
                      new_domains && new_domains.map((d,i)=>{
                        const random = Math.floor(Math.random() * params.length);
                        return(
                          <Col lg={3} className="mb-2">
                            <a className="fs-12 text-white opacity-5" href={'https://'+d.domain} target="_blank">{params[random]} {d.name}</a>
                          </Col>
                        )
                      })
                    }
              </Row> */}
              <Row>
                <Col lg={12} className="text-center">
                  <p className="opacity-6">
                    © Copyright {currentYear} All rights reserved by Shadowing
                    AI Inc. (Patent Pending)
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          {/* <div className="container-fluid d-md-flex py-4">
            <div className="mr-md-auto text-center text-md-left">
              <div className="copyright">
                &copy; Copyright {current_year} <strong><span> Shadowing AI </span></strong>. All Rights Reserved
              </div>
            </div>
            <div className="text-center text-md-right pt-3 pt-md-0">
              <Link to='/for_mentors' className="text-white mr-4">Mentors / Employees</Link>
              <Link to='/for_companies' className="text-white mr-4">Companies</Link>

              <Link to='/what_is_shadowing' className="text-white mr-4"  >What Is Shadowing</Link>
              <Link to='/how_shadowing_works' className="text-white mr-4"  >How Shadowing Works</Link>
             <Link to='/privacy_policy' className="text-white mr-4"  >Privacy Policy</Link>
             <Link to='/terms' className="text-white mr-4">Terms</Link>
             <a href='mailto:contact@shadowing.ai' target='_blank' className="text-white mr-4">Contact Us</a>
             <Link to='/about-us' className="text-white mr-4">About us</Link>
            </div>
          </div> */}
        </footer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    home_domains: state.Auth.home_domains,
    company_domain: state.Auth.company_domain
  };
};
export default connect(
  mapStateToProps,
  { getHomeDomains }
)(withRouter(Footer));
